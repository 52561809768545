<template>
  <v-container>
    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div>
          <h1 class="ml-2" style="font-weight: 200">Financial</h1>
        </div>
        <div class="ml-2">
          <b class="secondary--text subtitle-1">New Policy</b>
        </div>
        <NewFinancialPolicy />

        <div>
          <v-row>
            <v-col>
              <v-divider class="mb-3"></v-divider>
              <b class="secondary--text subtitle-1">
                <v-icon> mdi-cash-register </v-icon>
                Financial Policies
              </b>
              <FinancialPolicies
                v-for="(policy, i) in this.financialPolicies"
                :row="policy"
                :i="i"
                :key="i"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import FinancialPolicies from "../components/FinancialPolicies/FinancialPolicies.vue";
import NewFinancialPolicy from "../components/FinancialPolicies/NewFinancialPolicy.vue";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["financialPolicies"]),
  },
  mounted() {
    this.$store.dispatch("fetchFinancialPolicies");
  },
  components: { FinancialPolicies, NewFinancialPolicy },
};
</script>
