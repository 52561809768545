<template>
  <div class="pr-2 pl-2">
    <div class="d-flex flex-column" v-if="editMode">
      <div class="d-flex">
        <v-text-field
          :value="i + 1"
          label="#"
          outlined
          dense
          class="managrx-input mr-2"
          readonly
          filled
        ></v-text-field>
        <v-text-field
          v-model="newRow.name"
          label="Nombre"
          outlined
          dense
          class="managrx-input mr-2"
        ></v-text-field>
      </div>
      <div
        v-for="(singleData, j) in this.newRow.data"
        :key="j"
        class="d-flex ml-6"
      >
        <v-text-field
          :value="j + 1"
          label="#"
          outlined
          dense
          class="managrx-input mr-2"
          readonly
          filled
        ></v-text-field>
        <v-text-field
          v-model="singleData.percent"
          label="(0-100)%"
          outlined
          dense
          append-icon="mdi-percent-outline"
          class="managrx-input mr-2"
        ></v-text-field>
        <v-select
          v-model="singleData.ref_date"
          open-on-clear
          :items="options"
          return-object
          item-text="name"
          label="Reference Date"
          filled
          readonly
          dense
          outlined
          class="managrx-input mr-2"
        ></v-select>
        <v-text-field
          v-model="singleData.days"
          label="Dias"
          outlined
          dense
          class="managrx-input mr-2"
        ></v-text-field>
        <v-btn
          @click="deleteTuple(j)"
          depressed
          color="error  white--text"
          class="mr-2"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </div>
      <div class="mb-5">
        <v-btn
          depressed
          color="accent white--text"
          class="mr-2"
          @click="saveClick"
        >
          <v-icon> mdi-floppy</v-icon>
        </v-btn>
        <v-btn
          depressed
          color="warning white--text"
          class="mr-2"
          @click="backClick"
        >
          Atrás
        </v-btn>
      </div>
    </div>

    <div class="d-flex" v-if="!editMode">
      <v-text-field
        :value="i + 1"
        label="#"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
      ></v-text-field>
      <v-text-field
        v-model="modifiedRow.name"
        label="Nombre"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
      ></v-text-field>

      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text"
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { deleteRequest } from "@/managrx/serverCall";
export default {
  data() {
    return {
      editMode: false,
      deleteMode: false,
      newRow: {},
      modifiedRow: "",
      totalPercent: 0,
      preps: {
        days: "",
        refDate: null,
      },
    };
  },
  props: ["row", "i"],
  watch: {
    row: function () {
      this.load();
    },
  },
  computed: {
    options: () => {
      return [{ value: null, name: "Date Used" }];
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      let row = JSON.parse(this.row.policyJson);
      let data = [];
      for (let i = 0; i < row.length; i++) {
        if (row[i][1].toString().includes("$")) {
          if (row[i][1].toString().includes("-")) {
            this.preps.refDate = row[i][1].split("-");
            this.preps.refDate[1] = "-" + this.preps.refDate[1];

            this.preps.days = this.preps.refDate[1];
            this.preps.refDate = { value: this.preps.refDate[0] };
          } else {
            this.preps.refDate = row[i][1].split("+");
            this.preps.refDate[1] = "+" + this.preps.refDate[1];

            this.preps.days = this.preps.refDate[1];
            this.preps.refDate = { value: this.preps.refDate[0] };
          }
        } else {
          this.preps.days = parseInt(row[i][1]);
          this.preps.refDate = { value: null };
        }
        data.push({
          percent: row[i][0],
          ref_date: this.preps.refDate,
          days: this.preps.days,
        });
      }
      this.modifiedRow = {
        name: this.row.name,
        data,
      };
    },
    deleteClick() {
      this.deleteMode = true;
    },
    async saveClick() {
      let data = [];
      this.totalPercent = 0;
      for (let i = 0; i < this.newRow.data.length; i++) {
        data.push([
          parseInt(this.newRow.data[i].percent),
          this.newRow.data[i].ref_date && this.newRow.data[i].ref_date.value
            ? this.newRow.data[i].ref_date.value + this.newRow.data[i].days
            : parseInt(this.newRow.data[i].days),
        ]);

        this.totalPercent += parseInt(this.newRow.data[i].percent);
      }

      if (this.totalPercent == 100) {
        this.$store.dispatch("updateFinancialPolicies", {
          index: this.i,
          id: this.row.id,
          name: this.newRow.name,
          data,
        });
      }
      this.editMode = false;
    },

    async confirmDeleteClick() {
      let response = await deleteRequest("/payment_policy/" + this.row.id);
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("fetchFinancialPolicies");
      }
      this.backClick();
    },
    editClick() {
      this.newRow = { ...this.modifiedRow };
      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
    deleteTuple(index) {
      this.newRow.data.splice(index, 1);
    },
    resetNewRow() {
      this.newRow = {};
    },
  },
};
</script>
