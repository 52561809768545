<template>
  <div class="pr-2 pl-2 mb-6">
    <v-text-field
      v-model="name"
      label="Policy Name"
      outlined
      dense
      class="managrx-input mr-2"
    ></v-text-field>

    <div v-for="(policy, i) in this.policies" :key="i" class="d-flex">
      <v-text-field
        label="#"
        :value="i + 1"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
      ></v-text-field>
      <v-text-field
        v-model="policy.percent"
        label="(0-100)%"
        outlined
        append-icon="mdi-percent-outline"
        dense
        class="managrx-input mr-2"
      ></v-text-field>
      <v-select
        v-model="policy.reference_date"
        filled
        readonly
        open-on-clear
        :items="options"
        return-object
        item-text="name"
        label="Reference Date"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>
      <v-text-field
        v-model="policy.days"
        label="Dias"
        outlined
        dense
        class="managrx-input mr-2"
      ></v-text-field>
      <v-btn
        @click="deleteTuple(i)"
        depressed
        color="error  white--text"
        class="mr-2"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>

    <v-btn depressed color="accent white--text" class="mr-2" @click="addRow()">
      &plus;
    </v-btn>
    <v-btn depressed color="primary" class="mr-2" @click="addFinancialPolicy()">
      <v-icon> mdi-floppy</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { postRequest } from "@/managrx/serverCall";
export default {
  data() {
    return {
      name: "",
      policies: [{ percent: "", reference_date: null, days: "" }],
      totalPercent: 0,
    };
  },
  computed: {
    options: () => {
      return [{ value: null, name: "Fecha de uso" }];
    },
  },
  methods: {
    addRow() {
      this.policies.push({
        percent: "",
        reference_date: null,
        days: "",
      });
    },
    async addFinancialPolicy() {
      let data = [];
      this.totalPercent = 0;
      for (let i = 0; i < this.policies.length; i++) {
        data.push([
          parseInt(this.policies[i].percent),
          this.policies[i].reference_date &&
          this.policies[i].reference_date.value
            ? this.policies[i].reference_date.value + this.policies[i].days
            : parseInt(this.policies[i].days),
        ]);
        this.totalPercent += parseInt(this.policies[i].percent);
      }

      if (this.totalPercent == 100) {
        let obj = {
          name: this.name,
          policyJson: JSON.stringify(data),
        };

        try {
          let response = await postRequest("/payment_policy", obj);
          if (!response.success) {
            this.$store.dispatch("setNewNotification", response);
          } else {
            this.$store.dispatch("fetchFinancialPolicies");
            this.name = "";
            this.policies = [{ percent: "", reference_date: "", days: "" }];
          }
        } catch (error) {
          let notification = {
            type: "error",
            text: `${error?.response?.data?.error}`,
          };
          this.$store.dispatch("setNewNotification", notification);
        }
      } else {
        let msg = `Poliza Invalida, el porcentaje debe sumar un total de 100%`;
        let notif = {
          success: false,
          data: {
            type: "alert",
            text: msg,
          },
        };
        this.$store.dispatch("setNewNotification", notif);
      }
    },
    deleteTuple(index) {
      this.policies.splice(index, 1);
    },
  },
};
</script>
